<template>
  <section id="admin-registratin">
    <v-app>
      <v-main>
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" lg="6">
              <v-card max-width="400" class="mx-auto">
                <v-card-text>
                  <v-form @submit.prevent="register" class="ma-2">
                    <label class="caption">NAME</label>
                    <v-text-field v-model="form.name"/>
                    <label class="caption">EMAIL</label>
                    <v-text-field v-model="form.email" type="email"/>
                    <label class="caption">PASSWORD</label>
                    <v-text-field v-model="form.password" type="password"/>
                    <label class="caption">CONFIRM PASSWORD</label>
                    <v-text-field v-model="form.confirm_password" type="password"/>
                    <v-btn block large type="submit" color="success">
                      Submit
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </section>
</template>

<script>
export default {
  data: () => ({
    form: {
      name:null,
      email:null,
      password:null,
      confirm_password:null,
    }
  }),
  methods: {
    register() {
      this.$store.dispatch('admin/registerAction', this.form)
    }
  }
}
</script>